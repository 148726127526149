import { useLeaveSession as t } from "@croquet/react";
import { useCallback as r } from "react";
import { getCleanUrl as s } from "../utils/urls.js";
import "unique-names-generator";
function m() {
  const e = t();
  return r(() => {
    const o = s(new URL(window.location.href));
    window.history.replaceState({}, "", o.toString()), e();
  }, [e]);
}
export {
  m as default
};
