import { jsx as t } from "react/jsx-runtime";
import { Radio as o } from "antd";
import { useStateTogether as p } from "react-together";
function h({
  rtKey: r,
  items: a,
  ...l
}) {
  const [u, n] = p(r, null);
  return /* @__PURE__ */ t(
    o.Group,
    {
      ...l,
      value: u,
      onChange: (e) => n(e.target.value),
      children: a.map((e) => /* @__PURE__ */ t(o.Button, { value: e.value, children: e.label }, e.value))
    }
  );
}
export {
  h as default
};
