import { useCroquetContext as h, usePublish as l } from "@croquet/react";
import { useState as g, useEffect as b, useCallback as p } from "react";
import { getChat as a } from "../models/ReactTogetherModel.js";
import C from "./useMyId.js";
const u = [];
function I(s) {
  const r = C(), { view: n, model: t } = h(), [m, c] = g(() => {
    if (!t)
      return u;
    const e = a(t, s);
    return e ? e.messages : u;
  });
  b(() => {
    if (!t || !n) {
      c(u);
      return;
    }
    a(t, s) || n.publish(t.id, "createChat", { rtKey: s });
    const o = () => {
      const f = a(t, s);
      f && c([...f.messages]);
    };
    return o(), n.subscribe(s, "messageSent", o), () => n.unsubscribe(s, "messageSent", o);
  }, [t, n, s]);
  const i = l((e) => [s, "sendMessage", e]), d = p(
    (e) => {
      if (e.trim() === "")
        return;
      const o = Date.now();
      if (r === null) {
        console.warn("Cannot send message outside of a session");
        return;
      }
      i({
        message: e,
        senderId: r,
        sentAt: o
      });
    },
    [i, r]
  );
  return { messages: m, sendMessage: d };
}
export {
  I as default
};
