import { jsxs as r, jsx as l } from "react/jsx-runtime";
import { useState as u, useCallback as i } from "react";
function d({ sendMessage: n }) {
  const [e, a] = u(""), o = i(() => {
    e !== "" && (n(e), a(""));
  }, [e, n]);
  return /* @__PURE__ */ r("div", { className: "flex w-full p-2 overflow-hidden gap-2 rt-input-container", children: [
    /* @__PURE__ */ l(
      "input",
      {
        className: "rt-input-text",
        value: e,
        placeholder: "Type your message...",
        onChange: (t) => a(t.target.value),
        onKeyDown: (t) => {
          t.key === "Enter" && o();
        }
      }
    ),
    /* @__PURE__ */ l("button", { className: "rt-input-button", onClick: o, children: "Send" })
  ] });
}
export {
  d as default
};
