import { jsxs as a } from "react/jsx-runtime";
import { Button as c } from "antd";
import { useStateTogether as f } from "react-together";
function h({
  rtKey: o,
  onLabel: e = "Yes",
  offLabel: n = "No",
  onIcon: r,
  offIcon: s,
  ...i
}) {
  const [t, l] = f(o, !1);
  return /* @__PURE__ */ a(
    c,
    {
      ...i,
      type: t ? "primary" : "default",
      onClick: () => l(!t),
      children: [
        t ? r : s,
        t ? e : n
      ]
    }
  );
}
export {
  h as default
};
