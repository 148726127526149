import { jsx as t, Fragment as l } from "react/jsx-runtime";
import { TriStateCheckbox as u } from "primereact/tristatecheckbox";
import { useStateTogether as i } from "react-together";
function f({
  rtKey: o,
  ...e
}) {
  const [a, r] = i(o, !1);
  return /* @__PURE__ */ t(l, { children: /* @__PURE__ */ t(
    u,
    {
      ...e,
      onChange: (n) => r(n.value),
      value: a,
      className: `outline outline-1 outline-gray-400 rounded ${e.className}`
    }
  ) });
}
export {
  f as default
};
