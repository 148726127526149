import { jsx as o, Fragment as n } from "react/jsx-runtime";
import { Checkbox as s } from "primereact/checkbox";
import { useStateTogether as h } from "react-together";
function u({
  rtKey: t,
  ...e
}) {
  const [r, a] = h(t, !1);
  return /* @__PURE__ */ o(n, { children: /* @__PURE__ */ o(
    s,
    {
      ...e,
      onChange: (c) => a(c.checked || !1),
      checked: r,
      className: `outline outline-1 outline-gray-400 rounded ${e.className}`
    }
  ) });
}
export {
  u as default
};
