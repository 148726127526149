import { jsxs as c, Fragment as f, jsx as d } from "react/jsx-runtime";
import u from "color-hash";
import "react";
import g from "../hooks/useMyId.js";
import "../context/ReactTogetherContext.js";
import "@croquet/react";
import "object-hash";
import "../models/ReactTogetherModel.js";
import { c as h } from "../index-37cec024.js";
import "unique-names-generator";
const x = new u();
function $({
  rtKey: o,
  children: n,
  className: m,
  highlightMyself: r = !1
}) {
  const [l, i, p] = h(o), a = g();
  let e = {};
  const t = r ? i : i.filter((s) => s !== a);
  return (t.length > 0 || r && p) && (e = {
    outline: `2px solid ${x.hex(t[0] ?? o)}`,
    animation: "clippath 3s linear infinite",
    borderRadius: "10px"
  }), /* @__PURE__ */ c(f, { children: [
    /* @__PURE__ */ d("div", { ref: l, style: e, className: m, children: n }),
    !1
  ] });
}
export {
  $ as default
};
