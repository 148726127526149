import { jsx as t } from "react/jsx-runtime";
function e() {
  return /* @__PURE__ */ t(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      className: "rt-message-prompt-icon",
      children: /* @__PURE__ */ t(
        "path",
        {
          fill: "currentColor",
          d: "M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"
        }
      )
    }
  );
}
export {
  e as default
};
