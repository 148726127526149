import { jsx as n } from "react/jsx-runtime";
import { Select as u } from "antd";
import { useStateTogether as a } from "react-together";
function c({
  rtKey: e,
  ...t
}) {
  const [o, r] = a(e, null);
  return /* @__PURE__ */ n(u, { ...t, value: o, onChange: (l) => r(l) });
}
export {
  c as default
};
