import { jsx as i } from "react/jsx-runtime";
import "react";
import "@croquet/react";
import "../../models/ReactTogetherModel.js";
import "../../context/ReactTogetherContext.js";
import "object-hash";
import { b as f } from "../../index-37cec024.js";
import "unique-names-generator";
import '../../assets/Cursors.css';/* empty css                      */import l from "./UserCursor.js";
function O({
  // useCursorsOptions
  omitMyValue: t,
  deleteOnLeave: m,
  throttleDelay: u,
  // UserCursorOptions
  transitionDuration: a,
  getUserColor: e,
  // CursorsComponents
  components: r
} = {}) {
  const { allCursors: p } = f({
    omitMyValue: t,
    deleteOnLeave: m,
    throttleDelay: u
  }), C = (r == null ? void 0 : r.UserCursor) ?? l;
  return /* @__PURE__ */ i("div", { className: "cursors-container", children: Object.entries(p).map(
    ([s, o]) => o && /* @__PURE__ */ i(
      C,
      {
        userId: s,
        ...o,
        transitionDuration: a,
        getUserColor: e
      },
      s
    )
  ) });
}
export {
  O as default
};
