import { jsx as s } from "react/jsx-runtime";
import { CroquetRoot as u } from "@croquet/react";
import { ReactTogetherContext as R } from "../context/ReactTogetherContext.js";
import _ from "../models/ReactTogetherModel.js";
import { getSessionNameFromUrl as N, getSessionPasswordFromUrl as x } from "../utils/urls.js";
import { deriveNickname as C } from "../utils/users.js";
const d = "__rt-userId";
function A({
  children: m,
  sessionParams: o,
  sessionIgnoresUrl: f,
  userId: e,
  deriveNickname: p = C,
  rememberUsers: r = !1
}) {
  const { appId: w, apiKey: g } = o;
  let n;
  f || (n = {
    sessionUrl: window.location.origin + window.location.pathname
  });
  const a = new URL(window.location.href), h = N(a), v = x(a), S = o.model || _, l = h || o.name, c = v || o.password;
  if (r && e === void 0) {
    let i = localStorage.getItem(d);
    i === null && (i = Math.random().toString(36).substring(2, 15), localStorage.setItem(d, i)), e = i;
  }
  let t = o.viewData;
  return e !== void 0 && (t === void 0 ? t = { userId: e } : t.userId = e), /* @__PURE__ */ s(
    u,
    {
      sessionParams: {
        model: S,
        name: l,
        password: c,
        appId: w,
        apiKey: g,
        options: n,
        viewData: t
      },
      deferSession: !l || !c,
      showChildrenWithoutSession: !0,
      children: /* @__PURE__ */ s(R.Provider, { value: { deriveNickname: p, rememberUsers: r }, children: m })
    }
  );
}
export {
  A as default
};
