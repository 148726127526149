import { jsx as u } from "react/jsx-runtime";
import { Select as m } from "antd";
import { useStateTogether as a } from "react-together";
function f({
  rtKey: e,
  ...t
}) {
  const [o, r] = a(e, []);
  return /* @__PURE__ */ u(
    m,
    {
      mode: "multiple",
      ...t,
      value: o || [],
      onChange: (l) => r(l)
    }
  );
}
export {
  f as default
};
