import { jsx as c } from "react/jsx-runtime";
import { Switch as h } from "antd";
import { useStateTogether as f } from "react-together";
function s({
  rtKey: t,
  ...o
}) {
  const [e, r] = f(t, !1);
  return /* @__PURE__ */ c(
    h,
    {
      ...o,
      checked: e,
      onChange: () => r(!e)
    }
  );
}
export {
  s as default
};
