import { jsx as s, jsxs as r } from "react/jsx-runtime";
import { u as o } from "../../index-37cec024.js";
import "@croquet/react";
import "react";
import "../../models/ReactTogetherModel.js";
import "unique-names-generator";
import "../../context/ReactTogetherContext.js";
import "object-hash";
function c(e) {
  return new Date(e).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}
function v({
  isMe: e,
  senderId: a,
  message: t,
  sentAt: i,
  formatTime: m = c
}) {
  const n = o()[a] ?? a;
  return /* @__PURE__ */ s("div", { className: `rt-message-divider ${e ? "isMe" : ""}`, children: /* @__PURE__ */ r("div", { className: `rt-message-border ${e ? "isMe" : ""}`, children: [
    /* @__PURE__ */ s("span", { className: "rt-messageLabel", children: n }),
    /* @__PURE__ */ r("div", { children: [
      /* @__PURE__ */ s("span", { className: "rt-message-text", children: t }),
      " ",
      /* @__PURE__ */ s("span", { className: "rt-messageLabel", children: i ? m(i) : "???" })
    ] })
  ] }) });
}
export {
  v as default
};
