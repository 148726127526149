import { jsx as v } from "react/jsx-runtime";
import { ColorPicker as V } from "primereact/colorpicker";
import { useState as k, useCallback as f, useEffect as S } from "react";
import { useStateTogether as x } from "react-together";
function j({
  rtKey: c,
  publishWhileOpen: u = !1,
  onChange: l,
  ...s
}) {
  const n = s.defaultColor ?? "ff0000", [o, t] = k(n), [r, a] = x(c, o), i = s.inline, d = f(
    (e) => {
      e.value === null && (e.value = void 0), i || u ? a(e.value) : t(e.value), l == null || l(e);
    },
    [a, t, i, u, l]
  ), m = f(() => {
    a(o);
  }, [a, o]);
  return S(() => {
    t(r);
  }, [r, t]), /* @__PURE__ */ v(
    V,
    {
      ...s,
      value: o,
      onChange: d,
      onHide: m
    }
  );
}
export {
  j as default
};
