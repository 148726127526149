import { jsx as r, Fragment as m, jsxs as l } from "react/jsx-runtime";
import { Avatar as s } from "primereact/avatar";
import { AvatarGroup as c } from "primereact/avatargroup";
import "react";
import "@croquet/react";
import "../models/ReactTogetherModel.js";
import "../context/ReactTogetherContext.js";
import "object-hash";
import { a as h } from "../index-37cec024.js";
import "unique-names-generator";
function i({ nickname: t }) {
  return /* @__PURE__ */ r(
    s,
    {
      image: `https://api.dicebear.com/8.x/initials/svg?seed=${t}`,
      size: "normal",
      shape: "circle",
      label: t,
      pt: { root: { title: t } }
    }
  );
}
function z({
  maxAvatars: t = 3
}) {
  const e = h(), a = "normal", o = Math.max(e.length - (t - 1), 0);
  return /* @__PURE__ */ r(m, { children: /* @__PURE__ */ l(c, { pt: { root: { style: { gap: "10px" } } }, children: [
    e.slice(0, t - 1).map(({ nickname: n, userId: p }) => /* @__PURE__ */ r(i, { nickname: n }, p)),
    o > 0 && (o === 1 ? /* @__PURE__ */ r(i, { nickname: e[e.length - 1].nickname }) : /* @__PURE__ */ r(s, { label: `+${o}`, shape: "circle", size: a }))
  ] }) });
}
export {
  z as default
};
