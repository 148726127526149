import { uniqueNamesGenerator as a, adjectives as i, animals as r } from "unique-names-generator";
function n(e) {
  return a({
    seed: e,
    dictionaries: [i, r],
    length: 2,
    separator: " ",
    style: "capital"
  });
}
export {
  n as deriveNickname
};
