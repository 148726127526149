import { jsxs as c, jsx as a } from "react/jsx-runtime";
function i({
  senderId: r,
  sentAt: e,
  isMe: s,
  MessageAvatar: o,
  MessageBody: t,
  ...m
}) {
  return /* @__PURE__ */ c("div", { className: `rt-message-row ${s ? "isMe" : ""}`, children: [
    /* @__PURE__ */ a(o, { isMe: s, senderId: r }),
    /* @__PURE__ */ a(
      t,
      {
        isMe: s,
        senderId: r,
        sentAt: e,
        ...m
      }
    )
  ] });
}
export {
  i as default
};
