import { jsxs as u, jsx as t } from "react/jsx-runtime";
import { d as x } from "../../index-37cec024.js";
function g({
  messages: e,
  chatName: r,
  sendMessage: s,
  minimizeChat: a,
  ChatHeader: i,
  MessageList: o,
  MessageRow: n,
  MessageAvatar: m,
  MessageBody: d,
  ChatInput: h,
  ConnectPrompt: c,
  WelcomeMessage: f
}) {
  const p = x();
  return /* @__PURE__ */ u("div", { className: "rt-chatContainer", children: [
    /* @__PURE__ */ t(i, { chatName: r, minimizeChat: () => a() }),
    /* @__PURE__ */ t(
      o,
      {
        messages: e,
        MessageRow: n,
        MessageBody: d,
        MessageAvatar: m,
        ConnectPrompt: c,
        WelcomeMessage: f
      }
    ),
    p && /* @__PURE__ */ t(h, { sendMessage: s })
  ] });
}
export {
  g as default
};
