import { jsxs as n, jsx as t } from "react/jsx-runtime";
function i({
  chatName: a,
  expandChat: e
}) {
  return /* @__PURE__ */ n("button", { className: "rt-minChatContainer", onClick: () => e(), children: [
    /* @__PURE__ */ t("span", { className: "rt-chatHeader-title", children: a }),
    /* @__PURE__ */ t("div", { className: "rt-chatHeader-button", children: /* @__PURE__ */ t("span", { className: "rt-chatHeader-button-label", children: "+" }) })
  ] });
}
export {
  i as default
};
