import { jsx as m } from "react/jsx-runtime";
import { Slider as n } from "antd";
import { useStateTogether as f } from "react-together";
function d({
  rtKey: e,
  value: r,
  defaultValue: t,
  ...o
}) {
  const [a, i] = f(
    e,
    r || t || 0
  );
  return /* @__PURE__ */ m(n, { ...o, value: a, onChange: (l) => i(l) });
}
export {
  d as default
};
