import { jsx as m } from "react/jsx-runtime";
import { Rate as n } from "antd";
import { useStateTogether as u } from "react-together";
function p({ rtKey: e, ...t }) {
  const [o, r] = u(e, 0);
  return /* @__PURE__ */ m(n, { ...t, value: o, onChange: (a) => r(a) });
}
export {
  p as default
};
