var u = Object.defineProperty;
var d = (a, s, e) => s in a ? u(a, s, { enumerable: !0, configurable: !0, writable: !0, value: e }) : a[s] = e;
var h = (a, s, e) => (d(a, typeof s != "symbol" ? s + "" : s, e), e);
import c from "./ChatModel.js";
import { ReactModel as p } from "@croquet/react";
class g extends p {
  constructor() {
    super(...arguments);
    h(this, "state");
    h(this, "statePerUser");
    h(this, "statePerUserConfig");
    h(this, "chats");
    h(this, "viewIdUserIdMapping");
    h(this, "userIdCount");
  }
  init(e) {
    super.init({ ...e, trackViews: !0 }), this.state = /* @__PURE__ */ new Map(), this.statePerUser = /* @__PURE__ */ new Map(), this.statePerUserConfig = /* @__PURE__ */ new Map(), this.chats = /* @__PURE__ */ new Map(), this.viewIdUserIdMapping = /* @__PURE__ */ new Map(), this.userIdCount = /* @__PURE__ */ new Map(), this.subscribe(this.id, "setState", this.setState), this.subscribe(this.id, "setStatePerUser", this.setStatePerUser), this.subscribe(this.id, "configureStatePerUser", this.configureStatePerUser), this.subscribe(this.id, "functionTogether", this.functionTogether), this.subscribe(this.id, "createChat", this.createChat);
  }
  handleViewJoin(e, t) {
    var o;
    const i = ((o = t.viewData) == null ? void 0 : o.userId) ?? e;
    this.viewIdUserIdMapping.set(e, i);
    const r = this.userIdCount.get(i) ?? 0;
    this.userIdCount.set(i, r + 1);
  }
  handleViewExit(e) {
    const t = l(this, e), i = this.userIdCount.get(t);
    if (i === void 0) {
      console.warn(
        `No userIdCount found for userId ${t}. This is likely a bug. Please report it to the React Together team.`
      );
      return;
    }
    i <= 1 ? (this.statePerUser.forEach((r, o) => {
      const n = this.statePerUserConfig.get(o);
      n != null && n.keepValues || (r.delete(t), this.publish(o, "updated", {}));
    }), this.userIdCount.delete(t)) : this.userIdCount.set(t, i - 1), this.viewIdUserIdMapping.delete(e);
  }
  setState({ rtKey: e, value: t }) {
    this.state.set(e, t), this.publish(e, "updated", {});
  }
  setStatePerUser({ rtKey: e, userId: t, value: i }) {
    let r = this.statePerUser.get(e);
    r === void 0 && (r = /* @__PURE__ */ new Map(), this.statePerUser.set(e, r)), r.set(t, i), this.publish(e, "updated", {});
  }
  configureStatePerUser({ rtKey: e, options: t }) {
    const { keepValues: i } = t, r = this.statePerUserConfig.get(e) ?? {};
    i !== void 0 && (r.keepValues = i), this.statePerUserConfig.set(e, r);
  }
  functionTogether({ rtKey: e, args: t }) {
    this.publish(e, "call", t);
  }
  createChat({ rtKey: e }) {
    const t = c.create({ rtKey: e });
    this.chats.set(e, t);
  }
}
g.register("ReactTogetherModel");
function l(a, s) {
  const e = a.viewIdUserIdMapping.get(s);
  return e === void 0 ? (console.warn(
    `No userId found for viewId ${s}. This is likely a bug. Please report it to the React Together team.`
  ), s) : e;
}
function C(a, s) {
  return a.chats.get(s);
}
export {
  g as default,
  C as getChat,
  l as getUserId
};
