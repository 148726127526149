import { jsx as e } from "react/jsx-runtime";
import { useRef as d, useEffect as p } from "react";
import { d as g } from "../../index-37cec024.js";
import v from "../../hooks/useMyId.js";
function R({
  messages: t,
  MessageRow: i,
  MessageAvatar: n,
  MessageBody: s,
  ConnectPrompt: f,
  WelcomeMessage: c
}) {
  const l = v(), m = g(), r = d(null);
  return p(() => {
    r.current && r.current.scrollIntoView({ behavior: "smooth" });
  }, [t]), /* @__PURE__ */ e("div", { className: "rt-messageContainer", children: m ? t.length > 0 ? t.map(({ id: u, senderId: o, ...a }, h) => /* @__PURE__ */ e(
    "div",
    {
      ref: h === t.length - 1 ? r : void 0,
      children: /* @__PURE__ */ e(
        i,
        {
          senderId: o,
          ...a,
          isMe: o === l,
          MessageAvatar: n,
          MessageBody: s
        }
      )
    },
    u
  )) : /* @__PURE__ */ e(c, {}) : /* @__PURE__ */ e(f, {}) });
}
export {
  R as default
};
