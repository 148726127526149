import { jsx as e, jsxs as s } from "react/jsx-runtime";
import t from "./MessageIcon.js";
function a() {
  return /* @__PURE__ */ e("div", { className: "rt-message-prompt", children: /* @__PURE__ */ s("div", { className: "rt-message-prompt-content", children: [
    /* @__PURE__ */ e(t, {}),
    /* @__PURE__ */ e("h3", { children: "Start the Conversation" }),
    /* @__PURE__ */ e("p", { children: "Be the first one to send a message!" })
  ] }) });
}
export {
  a as default
};
