import { jsx as t, jsxs as o } from "react/jsx-runtime";
import "react";
import "@croquet/react";
import "../../models/ReactTogetherModel.js";
import "../../context/ReactTogetherContext.js";
import "object-hash";
import r from "../../hooks/useCreateRandomSession.js";
import "unique-names-generator";
import s from "./QuestionMarkIcon.js";
function u() {
  const e = r();
  return /* @__PURE__ */ t("div", { className: "rt-message-prompt", children: /* @__PURE__ */ o("div", { className: "rt-message-prompt-content", children: [
    /* @__PURE__ */ t(s, {}),
    /* @__PURE__ */ t("h3", { children: "Not Connected to a Session" }),
    /* @__PURE__ */ t("p", { children: "Create a new session to start chatting!" }),
    /* @__PURE__ */ t(
      "button",
      {
        className: "rt-message-prompt-button",
        onClick: () => e(),
        children: "Create New Session"
      }
    )
  ] }) });
}
export {
  u as default
};
