import { jsxs as r, jsx as a } from "react/jsx-runtime";
function s({
  chatName: e,
  minimizeChat: t
}) {
  return /* @__PURE__ */ r("button", { className: "rt-chatHeader", onClick: t, children: [
    /* @__PURE__ */ a("span", { className: "rt-chatHeader-title", children: e }),
    /* @__PURE__ */ a("div", { className: "rt-chatHeader-button", children: /* @__PURE__ */ a("span", { className: "rt-chatHeader-button-label", children: "-" }) })
  ] });
}
export {
  s as default
};
