import { useContext as e } from "react";
import { ReactTogetherContext as o } from "../context/ReactTogetherContext.js";
function u() {
  const t = e(o);
  if (t === null)
    throw new Error(
      "useReactTogetherContext must be used within a ReactTogetherProvider"
    );
  return t;
}
export {
  u as default
};
